<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Are there resources or services that need to be added that would help you learn the content
        better or more efficiently (quicker)?
        <i>Please specify and describe anything that even MIGHT be helpful. Open ended. </i>
      </p>

      <s-textarea v-model="inputs.input1" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'OleMissSurveyQ7',
  components: {
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
<style scoped></style>
